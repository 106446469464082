import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Device } from '@capacitor/device';
import { Store } from '@ngxs/store';

import { ProgramDurationsEnum } from '@shared/enums';
import { AvailableDiscount } from '@shared/models';
import { SetProgram } from '@store/program';

@Component({
  selector: 'app-payment-split',
  templateUrl: './payment-split.component.html',
  styleUrls: ['./payment-split.component.scss'],
  providers: [InAppBrowser],
})
export class PaymentSplitComponent {
  @Input() isSkipped = false;
  @Input() weekSplitPrice = 0;
  @Input() total = 0;
  @Input() availableDiscounts: AvailableDiscount[] = [];
  @Input() duration: ProgramDurationsEnum;

  public readonly ProgramDurationsEnum = ProgramDurationsEnum;

  constructor(private http: HttpClient, private iab: InAppBrowser, private store: Store) {}

  async openExternalLink(url: string) {
    const { platform } = await Device.getInfo();

    if (platform === 'web') {
      window.open(url, '_blank');
      return;
    }

    try {
      const pdfBlob = await this.http.get(url, { responseType: 'blob' }).toPromise();
      const reader = new FileReader();
      reader.readAsDataURL(pdfBlob);
      reader.onloadend = async () => {
        const base64data = reader.result as string;

        this.iab.create(base64data, '_blank', { location: 'no' });
      };
    } catch (error) {
      console.error('Error opening PDF file:', error);
    }
  }

  public setDuration(durationId: ProgramDurationsEnum): void {
    if (durationId === this.duration) {
      return;
    }

    this.store.dispatch(new SetProgram({ durationId }));
  }
}
