import dayjs from 'dayjs';

import { getMonthName } from './get-month-name.util';

export function getDateDescription(date: string): string {
  const deliveryDay = dayjs(date).startOf('day');
  const today = dayjs().startOf('day');
  const tomorrow = today.add(1, 'day');

  if (deliveryDay.isSame(today)) {
    return 'сегодня до 12:00';
  }

  if (deliveryDay.isSame(tomorrow)) {
    return 'завтра';
  }

  const currentDay = deliveryDay.subtract(1, 'day');
  const day = currentDay.format('D');
  const month = getMonthName(Number(currentDay.format('M')));

  return `${day} ${month}`;
}
