<div class="stories-container">
  <div *ngFor="let story of storiesData$ | async" class="story" (click)="openStoryViewer(story)">
    <div class="story-image ion-activatable" [class.isShowed]="story.isShowed || (story.id | isShowedStories | async)">
      <img [src]="'./assets/images/stories/' + story.image" />
      <ion-ripple-effect></ion-ripple-effect>
    </div>

    <p class="story-text">{{ story.text }}</p>
  </div>
</div>
