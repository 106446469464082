import { Directive, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[appThirdPartyLink]',
})
export class ThirdPartyLinkDirective implements OnInit {
  private url: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private elementRef: ElementRef,
  ) {}

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    window.open(this.url, this.elementRef.nativeElement.target || '_blank');
  }

  ngOnInit(): void {
    this.url = this.elementRef.nativeElement.href;
    this.elementRef.nativeElement.href = '';
    this.elementRef.nativeElement.rel = 'noopener nofollow';
  }
}
