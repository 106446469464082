import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { MinimumPrice } from '@shared/models';

@Injectable({
  providedIn: 'root',
})
export class InfoApiService {
  constructor(private http: HttpClient) {}

  public getMinimumPrices(): Observable<MinimumPrice[]> {
    return this.http.get<MinimumPrice[]>(`${environment.url.minimumPrices}`);
  }
}
