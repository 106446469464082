import { Pipe, PipeTransform } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Pipe({
  name: 'isShowedStories',
})
export class IsShowedStories implements PipeTransform {
  async transform(id: number): Promise<boolean> {
    const { value } = await Preferences.get({ key: 'stories_' + id });

    return Boolean(value);
  }
}
