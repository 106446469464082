import { NgModule } from '@angular/core';

import { ProgramNamePipe } from './program-name.pipe';
import { CustomNumberPipe } from './custom-number.pipe';
import { PluralizePipe } from './pluralize.pipe';
import { DishImagePipe } from './dish-image.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { FirstDeliveryDayPipe } from './first-delivery-day.pipe';
import { MonthNameWordDeclensionPipe } from './month-name-world-declension.pipe';
import { IngredientsPipe } from './ingredients.pipe';
import { SanitizedHtmlPipe } from './sanitized-html.pipe';
import { FunctionPipe } from './f.pipe';
import { DishTransformPipe } from './dish-transform.pipe';
import { MealCountPipe } from './meal-count.pipe';
import { AvailableDiscountPipe } from './available-discount.pipe';
import { ProgramDescriptionPipe } from './program-description.pipe';
import { DateAgoPipe } from './date-ago.pipe';
import { CustomSizePipe } from './custom-size.pipe';
import { IsShowedStories } from './stories-is-showed.pipe';

export const pipes = [
  ProgramNamePipe,
  ProgramDescriptionPipe,
  CustomNumberPipe,
  PluralizePipe,
  DishImagePipe,
  FormatDatePipe,
  FirstDeliveryDayPipe,
  MonthNameWordDeclensionPipe,
  IngredientsPipe,
  SanitizedHtmlPipe,
  FunctionPipe,
  DishTransformPipe,
  MealCountPipe,
  AvailableDiscountPipe,
  DateAgoPipe,
  CustomSizePipe,
  IsShowedStories,
];

@NgModule({
  declarations: [...pipes],
  providers: [MonthNameWordDeclensionPipe],
  exports: [...pipes],
})
export class PipesModule {}
