import { IonModal, ModalController } from '@ionic/angular';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
  ViewChild,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  inject,
} from '@angular/core';
import { Observable, takeUntil, BehaviorSubject, catchError, filter, of, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { ModalCustomEvent } from '@ionic/core/components';

import { Preferences } from '@capacitor/preferences';

import { ClientMenuEventsEnum, PackageStateEnum } from '@modules/client-menu/enums';
import { ClientMenuDish } from '@modules/client-menu/models';
import { ImageTypeEnum, ImageFileTypeEnum, LocalStorageKeysEnum } from '@shared/enums';
import { DishImagePipe } from '@shared/pipes';
import { NgOnDestroyService, RudderStackService } from '@shared/services';

import {
  AddChangeToActiveSubscription,
  ClientMenuSelectors,
  LoadReplacementDishes,
  RemoveChangeFromActiveSubscription,
} from '@store/client-menu';
import { ClientSubscriptionPackage } from '@shared/models';
import { ChooseDishPortionComponent } from '../choose-dish-portion';
import { DishHelper, groupDishesWithSameTitles } from '@modules/client-menu/helpers';

@Component({
  selector: 'app-select-dish-modal',
  styleUrls: ['select-dish-modal.component.scss'],
  templateUrl: 'select-dish-modal.component.html',
  providers: [NgOnDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectDishModalComponent implements OnInit {
  @Input() dish: ClientMenuDish;
  @Input() package: ClientSubscriptionPackage;
  @Input() isFromDetailsModal: boolean;
  @Input() isFromAdditionModal: boolean = false;
  @Input() isFromSelectDishModal: boolean = false;

  @Output() public undoSelect = new EventEmitter();
  @Output() public rateDish = new EventEmitter();

  @ViewChild(IonModal) modal: IonModal;

  private replacementDishes$ = inject(Store).select(ClientMenuSelectors.replacementsDishes);

  public otherDishes$: Observable<ClientMenuDish[]>;
  public sameTypeDishes$: Observable<ClientMenuDish[]>;

  public isModalOpen = false;
  public otherDishes: ClientMenuDish[];
  public sameTypeDishes: ClientMenuDish[];
  public retailDishes: ClientMenuDish[];

  public isShownSortDropdown: boolean = false;
  public filterMethod: string = 'filterByCcal';
  public allDishes: ClientMenuDish[];
  public isLoading$ = new BehaviorSubject<boolean>(true);

  readonly ImageTypeEnum = ImageTypeEnum;
  readonly ImageFileTypeEnum = ImageFileTypeEnum;
  readonly ClientMenuEventsEnum = ClientMenuEventsEnum;
  readonly PackageStateEnum = PackageStateEnum;

  constructor(
    private ngOnDestroy$: NgOnDestroyService,
    private dishImagePipe: DishImagePipe,
    private rudderstackService: RudderStackService,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private modalCtrl: ModalController,
  ) {}

  ngOnInit(): void {
    this.listenChangeRoute();
    this.loadDishes();
  }

  // Use the helper for tooltip logic
  public getTooltipText(): string {
    return DishHelper.getTooltipText(this.dish, this.package);
  }

  // Use the helper for tooltip disable state
  public isTooltipDisabled(): boolean {
    return DishHelper.isTooltipDisabled(this.dish, this.package);
  }

  // Use the helper to determine if button should be shown
  public shouldShowButton(params: any[]): boolean {
    const [dish, pkg] = params;
    return DishHelper.shouldShowButton(dish, pkg);
  }

  // Use the helper to get dynamic button text
  public getButtonText(params: any[]): string {
    const [dish, pkg, isFromDetailsModal, isFromAdditionModal] = params;
    return DishHelper.getButtonText(dish, pkg, isFromDetailsModal, isFromAdditionModal);
  }

  // Use the helper to get button class
  public getButtonClass(isFromDetailsModal: boolean): string {
    return DishHelper.getButtonClass(isFromDetailsModal);
  }

  // Use the helper to determine if button should be disabled
  public isButtonDisabled(params: any[]): boolean {
    const [dish, pkg, isFromDetailsModal, isFromAdditionModal] = params;
    return DishHelper.isButtonDisabled(dish, pkg, isFromDetailsModal, isFromAdditionModal);
  }

  // Button click handler remains unchanged
  public handleButtonClick(): void {
    const buttonType = DishHelper.getButtonType(this.dish, this.package, this.isFromDetailsModal, this.isFromAdditionModal);

    switch (buttonType) {
      case 'replace':
        this.openModal(this.dish);
        break;
      case 'replaceFromDetails':
        this.selectDishFromDetailsModal(this.dish);
        break;
      case 'addDish':
        this.addDish(this.dish);
        break;
      case 'selectPortion':
        this.openSelectPortionModal(this.dish);
        break;
      case 'undoChange':
        this.undoDishChange(this.dish.id);
        break;
      case 'rateDish':
        this.setDishRating();
        break;
      case 'deselect':
        this.deselect(this.dish, this.package?.packageId);
        break;
      default:
        break;
    }
  }

  setOpen(isOpen: boolean): void {
    this.isModalOpen = isOpen;
  }

  private loadDishes(): void {
    if (this.isFromAdditionModal) {
      return;
    }
    this.isLoading$.next(true);
    this.replacementDishes$
      .pipe(
        catchError(() => {
          this.isLoading$.next(false);
          this.trackLackOfAvailableDishes();

          return of([]);
        }),
        filter<ClientMenuDish[]>(Boolean),
        tap(dishes => {
          this.allDishes = dishes;
          if (this.allDishes.length > 0) {
            this.isLoading$.next(false);
          }

          Preferences.set({
            key: LocalStorageKeysEnum.replacementDishes,
            value: JSON.stringify(dishes),
          });

          if (this.dish?.mealType !== undefined) {
            Preferences.set({
              key: LocalStorageKeysEnum.replacableDishMealType,
              value: JSON.stringify(this.dish?.mealType),
            });
          }

          const mergedGroupedArray = groupDishesWithSameTitles(this.allDishes);
          this.otherDishes = mergedGroupedArray.filter(item => !item.isSameMenuType);
          this.sameTypeDishes = mergedGroupedArray.filter(item => item.isSameMenuType);
          this.retailDishes = mergedGroupedArray.filter(item => item.isRetail);
          this.filterByCcal();
          this.cdr.markForCheck();
        }),
        takeUntil(this.ngOnDestroy$),
      )
      .subscribe();
  }

  private trackLackOfAvailableDishes(): void {
    if (this.sameTypeDishes?.length || this.otherDishes?.length) {
      return;
    }

    this.rudderstackService.track(ClientMenuEventsEnum.noAvailableDishes);
  }

  private listenChangeRoute(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.ngOnDestroy$)).subscribe(() => {
      if (this.isModalOpen) {
        this.closeModal();
      }
    });
  }

  async openModal(dish: ClientMenuDish): Promise<void> {
    this.isLoading$.next(true);

    await Preferences.set({
      key: LocalStorageKeysEnum.selectedDishToReplace,
      value: JSON.stringify(dish),
    });

    this.setOpen(true);
    this.store.dispatch(new LoadReplacementDishes(this.package.packageId, this.dish.id));
    this.rudderstackService.track(ClientMenuEventsEnum.dishSelected, dish);
  }

  public showSortDropdown(): void {
    this.rudderstackService.track(ClientMenuEventsEnum.openSorting);
    this.isShownSortDropdown = !this.isShownSortDropdown;
  }

  public filterByCcal() {
    this.sameTypeDishes = this.sameTypeDishes.sort((a, b) => a.caloricity - b.caloricity);
    this.otherDishes = this.otherDishes.sort((a, b) => a.caloricity - b.caloricity);
    this.retailDishes = this.retailDishes.sort((a, b) => a.caloricity - b.caloricity);
  }

  public filterByDishTitle() {
    this.sameTypeDishes = this.sameTypeDishes.sort((a, b) => a.title.localeCompare(b.title));
    this.otherDishes = this.otherDishes.sort((a, b) => a.title.localeCompare(b.title));
    this.retailDishes = this.retailDishes.sort((a, b) => a.title.localeCompare(b.title));
  }

  public filterByPrice() {
    this.sameTypeDishes = this.sameTypeDishes.sort((a, b) => a.priceIncrease - b.priceIncrease);
    this.otherDishes = this.otherDishes.sort((a, b) => a.priceIncrease - b.priceIncrease);
    this.retailDishes = this.retailDishes.sort((a, b) => a.priceIncrease - b.priceIncrease);
  }

  public selectFilterMethod(filterMethod: string) {
    this.filterMethod = filterMethod;
  }

  public sortDishes() {
    const mergedGroupedArray = groupDishesWithSameTitles(this.allDishes);
    this.otherDishes = mergedGroupedArray.filter(item => !item.isSameMenuType);
    this.sameTypeDishes = mergedGroupedArray.filter(item => item.isSameMenuType);
    this.retailDishes = mergedGroupedArray.filter(item => item.isRetail);

    if (this.filterMethod === 'filterByCcal') {
      this.filterByCcal();
    }
    if (this.filterMethod === 'filterByTitle') {
      this.filterByDishTitle();
    }
    if (this.filterMethod === 'filterByPrice') {
      this.filterByPrice();
    }
    this.isShownSortDropdown = false;

    this.rudderstackService.track(ClientMenuEventsEnum.changeSort, { filterMethod: this.filterMethod });
    this.cdr.markForCheck();
  }

  public setDishRating(): void {
    this.rateDish.emit();
  }

  closeModal(): void {
    this.rudderstackService.track(ClientMenuEventsEnum.dishPopupClosed);
    this.modal.dismiss(null, 'cancel');
    this.setOpen(false);
    this.store.dispatch(new LoadReplacementDishes(null, null));
  }

  onWillDismiss(modalEvent: Event): void {
    const customModalEvent = modalEvent as ModalCustomEvent;
    this.modal.onDidDismiss().then(() => {
      this.modalCtrl.dismiss();
    });

    if (customModalEvent.detail.role === 'backdrop') {
      this.setOpen(false);
      this.store.dispatch(new LoadReplacementDishes(null, null));
    }
  }

  async onSelectDish(dish: ClientMenuDish): Promise<void> {
    await this.trackDishSelection(ClientMenuEventsEnum.newDishSelected, dish, false);
    await this.rudderstackService.track(ClientMenuEventsEnum.dishPopupClosed);
    this.store.dispatch(new AddChangeToActiveSubscription(this.package.packageId, this.dish, dish));
    this.modal.dismiss(dish, 'confirm');
    this.setOpen(false);

    this.modal.onDidDismiss().then(() => {
      this.modalCtrl.dismiss();
    });
  }

  public async selectDishFromDetailsModal(dish: ClientMenuDish) {
    if (this.isFromSelectDishModal) {
      this.modalCtrl.dismiss(dish);
    } else {
      this.openModal(dish);
    }
  }

  public async addDish(dish: ClientMenuDish): Promise<void> {
    if (this.isArray(dish.id)) {
      const selectedDishId = await Preferences.get({ key: LocalStorageKeysEnum.selectedDishForPortion });
      const dishesToReplace = await Preferences.get({ key: LocalStorageKeysEnum.replacementDishes });
      const dish = JSON.parse(dishesToReplace.value).filter(d => d.id === JSON.parse(selectedDishId.value))[0];
      this.modal.dismiss(dish, 'confirm');
      this.modalCtrl.dismiss(dish);
    }
    this.modal.dismiss(dish, 'confirm');
    this.modalCtrl.dismiss(dish);
  }

  async openSelectPortionModal(dish: ClientMenuDish): Promise<void> {
    this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: ChooseDishPortionComponent,
      cssClass: dish.id?.length > 2 ? 'choose-portion-modal-large' : 'choose-portion-modal',
      mode: 'md',
      breakpoints: [0, 0.25, 0.5, 1],
      initialBreakpoint: 1,
      componentProps: {
        dish,
        isFromAdditionModal: this.isFromAdditionModal,
      },
    });
    modal.present();

    const result = await modal.onDidDismiss();
    if (result.data) {
      const dishToChange = await Preferences.get({
        key: LocalStorageKeysEnum.selectedDishToReplace,
      });
      const dishToChangeValue = JSON.parse(dishToChange.value);

      if (this.isFromAdditionModal) {
        this.store.dispatch(new AddChangeToActiveSubscription(this.package.packageId, null, result.data));
      } else {
        this.store.dispatch(new AddChangeToActiveSubscription(this.package.packageId, dishToChangeValue, result.data));
      }

      await this.trackDishSelection(ClientMenuEventsEnum.newDishSelected, result.data, false);
      await this.rudderstackService.track(ClientMenuEventsEnum.dishPopupClosed);
      this.modal.dismiss(result.data, 'confirm');
      this.setOpen(false);
      this.modalCtrl.dismiss();
    }
  }

  async undoDishChange(dishId: string): Promise<void> {
    this.rudderstackService.track(ClientMenuEventsEnum.dishCanceled, { dishId });
    this.undoSelect.emit();
  }

  public deselect(dish: ClientMenuDish, packageId): void {
    this.store.dispatch(new RemoveChangeFromActiveSubscription(packageId, dish));
    this.trackAdditionCanceling(dish);

    if (this.isFromDetailsModal) {
      this.modalCtrl.dismiss();
    }
  }

  private trackAdditionCanceling({ originalDishId, id }: ClientMenuDish): void {
    const isAddedDish = !originalDishId;
    if (!isAddedDish) {
      return;
    }

    this.rudderstackService.track(ClientMenuEventsEnum.dishAdditionCanceled, { dishId: id });
  }

  private trackDishSelection(eventName: string, { id, title }: ClientMenuDish, containsExcludedIngredients: boolean): void {
    this.rudderstackService.track(eventName, { dishId: id, title, containsExcludedIngredients });
  }

  public isArray(property: any): boolean {
    return Array.isArray(property);
  }

  getImageSources(dishCode: string, imageType: ImageTypeEnum, fileType: ImageFileTypeEnum): string {
    if (!dishCode) {
      return null;
    }

    return this.dishImagePipe.transform(dishCode, imageType, fileType);
  }
}
