import { ChangeDetectionStrategy, Input, Component, inject } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';

import { RudderStackService } from '@shared/services';
import { ClientSubscriptionDetails, ClientSubscriptionPackage } from '@shared/models';
import { AddChangeToActiveSubscription, ClientMenuSelectors } from '@store/client-menu';
import { ClientMenuEventsEnum, PackageStateEnum } from '@modules/client-menu/enums';
import { ClientMenuDish } from '@modules/client-menu/models';
import { DishAdditionDialogComponent } from '../dish-addition-dialog';

@Component({
  selector: 'app-dishes-list',
  templateUrl: './dishes-list.component.html',
  styleUrls: ['./dishes-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DishesListComponent {
  @Input() scrollableContainer: HTMLElement;

  public activeSubscriptionDetails$: Observable<ClientSubscriptionDetails> = inject(Store).select(ClientMenuSelectors.subscriptionDetails);
  public activePackage$: Observable<ClientSubscriptionPackage> = inject(Store).select(ClientMenuSelectors.activePackage);

  public additionDisablementStatus$: Observable<boolean> = this.activePackage$.pipe(
    filter<ClientSubscriptionPackage>(Boolean),
    map(({ hasCustomization, isCustomizable, menuItems, availableAdditionsCount, packageState, hasConfirmedCustomization }) => {
      const addedDishesCount = menuItems?.filter(({ isAdded }) => isAdded).length || 0;
      const isAdditionsLimitReached = availableAdditionsCount - addedDishesCount <= 0;
      const status =
        packageState !== PackageStateEnum.onCooking &&
        isCustomizable &&
        !hasCustomization &&
        !hasConfirmedCustomization &&
        !isAdditionsLimitReached;

      return status;
    }),
  );

  constructor(private modalCtrl: ModalController, private store: Store, private rudderstack: RudderStackService) {}

  public async openDishAdditionDialog(selectedPackage: ClientSubscriptionPackage, isChildrenView: boolean): Promise<void> {
    this.rudderstack.track(ClientMenuEventsEnum.addDishBtnClick);

    const modal = await this.modalCtrl.create({
      component: DishAdditionDialogComponent,
      cssClass: 'dish-addition-modal',
      mode: 'md',
      componentProps: {
        package: selectedPackage,
        isChildrenView,
      },
    });

    modal.present();

    const result = await modal.onDidDismiss();
    const addedDish: ClientMenuDish = result.data;

    if (addedDish) {
      this.store.dispatch(new AddChangeToActiveSubscription(selectedPackage.packageId, null, addedDish));

      this.rudderstack.track(ClientMenuEventsEnum.additionalDishSelected, {
        dishId: addedDish.id,
        title: addedDish.title,
        containsExcludedIngredients: false,
      });
    }

    this.rudderstack.track(ClientMenuEventsEnum.dishAdditionPopupClosed);
  }
}
