import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Preferences } from '@capacitor/preferences';

import { LocalStorageKeysEnum } from '@shared/enums';
import { getFormattedPhone } from '@shared/utils';

const resendTimeInSec = 60;

@Injectable()
export class TimerService implements OnDestroy {
  timeLeft$ = new BehaviorSubject(0);
  timer;

  get timeLeftForView$(): Observable<string> {
    return this.timeLeft$.pipe(map(value => this.formatToString(value)));
  }

  get timeLeft(): number {
    return this.timeLeft$.getValue();
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  async init(): Promise<{ isStarted: boolean; phoneNumber?: string }> {
    const storedData = await Preferences.get({ key: LocalStorageKeysEnum.codeRequestPhoneData });
    const hasData = storedData && storedData?.value;

    if (!hasData) {
      return {
        isStarted: false,
      };
    }

    const { phoneNumber, timestamp } = JSON.parse(storedData.value);
    const secondsSinceTimerStart = Math.round(Math.abs((Date.now() - timestamp) / 1000));

    if (secondsSinceTimerStart > resendTimeInSec) {
      return { isStarted: false };
    }

    await this.start(phoneNumber, resendTimeInSec - secondsSinceTimerStart, timestamp);

    return {
      isStarted: true,
      phoneNumber,
    };
  }

  async start(phoneNumber: string, timerInSec: number = resendTimeInSec, initialTimerTimestamp?: number) {
    await this.storeTimerData(getFormattedPhone(phoneNumber), initialTimerTimestamp);

    this.timeLeft$.next(timerInSec);
    this.timer = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft$.next(this.timeLeft - 1);
      } else {
        this.stop();
      }
    }, 1000);
  }

  async stop() {
    clearInterval(this.timer);
    this.timeLeft$.next(0);
    await Preferences.remove({ key: LocalStorageKeysEnum.codeRequestPhoneData });
  }

  async clear() {
    await Preferences.remove({ key: LocalStorageKeysEnum.codeRequestPhoneData });
  }

  private async storeTimerData(phoneNumber: string, initialTimestamp: number) {
    const data = {
      phoneNumber,
      timestamp: initialTimestamp || Date.now(),
    };

    await Preferences.set({
      key: LocalStorageKeysEnum.codeRequestPhoneData,
      value: JSON.stringify(data),
    });
  }

  private formatToString(value): string {
    return value < 10 ? '0' + value : value;
  }
}
