import { SlideIdEnum, StorieIdEnum } from './stories.enum';
import { Story } from './stories.model';

export const STORIES_DATA: Story[] = [
  {
    id: StorieIdEnum.FREE_REPLACEMENTS,
    image: 'story_1.png',
    text: 'Бесплатные замены',
    isShowed: false,
    slides: [
      {
        id: SlideIdEnum.FREE_REPLACEMENT,
        title: 'Бесплатная замена блюд',
        imageMiddle: '/assets/images/stories/1/free-replacement_middle_1.png',
        textParagraphs: ['Более 100 бесплатных замен в каждой доставке'],
        backgroundColor: '#FFCB7F',
        buttonText: 'Выбрать рацион',
        buttonLink: '/cabinet/create/list',
      },
    ],
  },
  {
    id: StorieIdEnum.PERSONAL_DISCOUNTS,
    image: 'story_2.png',
    text: 'Персональные скидки',
    isShowed: false,
    slides: [
      {
        id: SlideIdEnum.PERSONAL_DISCOUNT_1,
        title: 'Скидка 10 на вторую подписку и более',
        imageTop: '/assets/images/stories/2/family_top_1.png',
        textParagraphs: [
          'Получите скидку 10% при заказе двух и более рационов по одному адресу и с доставкой в одно время.',
          'Даже если подписки не совпадут по длине, то скидка подействует на пересекающиеся дни.',
          'А еще она суммируется с другими скидками.',
        ],
        backgroundColor: '#EEBD92',
        buttonText: 'Оформить',
        buttonLink: '',
      },
      {
        id: SlideIdEnum.PERSONAL_DISCOUNT_2,
        title: '5% за отзыв в соцсети',
        imageMiddle: '/assets/images/stories/2/discount_middle_2.png',
        textParagraphs: [
          'Напишите отзыв o justfood в VK, отметив наш аккаунт либо на нашей странице и получи скидку 5% на следующий заказ. То же самое работает с Яндекс и Google.',
        ],
        backgroundColor: '#FFC979',
        buttonTextAbove: 'Бонус суммируется с «Приведите друга»',
        buttonText: 'Перейти в VK',
        buttonLink: 'https://vk.com/justfoodpro',
        buttonIcon: 'vk-white',
      },
    ],
  },
  {
    id: StorieIdEnum.INVITE_FRIENDS,
    image: 'story_3.png',
    text: 'Пригласи друга',
    isShowed: false,
    slides: [
      {
        id: SlideIdEnum.INVITE_FRIENDS,
        title: 'Пригласить друга',
        imageMiddle: '/assets/images/stories/3/invite_middle_1.png',
        textParagraphs: ['По 2000 ₽ каждому: вам и вашему другу, реальными деньгами.', 'Чтобы получить деньги, поделитесь промокодом'],
        backgroundColor: '#FFABB0',
        buttonTextBelow: 'Изменить прокомод можно, написав нам в чат. Чтобы всё сработало, заказ должен быть от 5 дней',
      },
    ],
  },
];

export const STORIES_DATA_ON_THANKS_PAGE: Story = {
  id: StorieIdEnum.THANKS_PAGE,
  image: '',
  text: '',
  isShowed: false,
  slides: [
    {
      id: SlideIdEnum.THANKS_PAGE_DISCOUNT,
      title: '5% за отзыв в соцсети',
      imageTop: '/assets/images/stories/thanks/thanks-discount.png',
      textParagraphs: [
        'Напишите отзыв o justfood в VK, отметив наш аккаунт либо на нашей странице и получи скидку 5% на следующий заказ. То же самое работает с Яндекс и Google.',
      ],
      textColor: '#201F1D',
      backgroundColor: '#E2FEA8',
      buttonTextAbove: 'Бонус суммируется с «Приведите друга»',
      buttonText: 'Перейти в VK',
      buttonLink: 'https://vk.com/justfoodpro',
      buttonIcon: 'vk-white',
    },
  ],
};
