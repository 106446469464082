import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NO_ERRORS_SCHEMA, NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { Appmetrica } from 'capacitor-appmetrica';
import * as Sentry from '@sentry/angular';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StoreModule } from '@store/index';
import { ApiInterceptor } from '@shared/interceptors';

import { NoConnectionComponent } from '@shared/components';
import { IntroModule } from '@modules/intro';
import { LoginModule } from '@modules/login';
import { LayoutModule } from '@modules/layout';
import { AdminModule } from '@modules/admin';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgOnDestroyService } from '@shared/services';
import { SharedModule } from '@shared/shared.module';

registerLocaleData(localeRu, 'ru');

@NgModule({
  declarations: [AppComponent, NoConnectionComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IntroModule,
    LoginModule,
    LayoutModule,
    AdminModule,
    StoreModule,
    SharedModule,
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    Appmetrica,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'ru',
    },
    NgOnDestroyService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
