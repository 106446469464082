import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Feedback, FeedbackDishRequest, FeedbackToSend } from '../models';

@Injectable({
  providedIn: 'root',
})
export class FeedbackApiService {
  constructor(private http: HttpClient) {}

  /**
   * Метод проверяет, нужно ли собрать обратную связь о меню
   * @returns Feedback
   */
  public generateMenuFeedback(): Observable<Feedback> {
    const url = `${environment.url.feedBack}/generate/menu`;

    return this.http.post<Feedback>(url, {});
  }

  /**
   * Метод проверяет, нужно ли собрать обратную связь о блюде
   * @param data FeedbackDishRequest
   * @returns Feedback
   */
  public generateDishFeedback(data: FeedbackDishRequest): Observable<Feedback> {
    const url = `${environment.url.feedBack}/generate/dish`;

    return this.http.post<Feedback>(url, data);
  }

  /**
   * Метод проверяет, нужно ли собрать обратную связь о подписках клиента
   * @returns Feedback
   */
  public generateSubscriptionFeedback(): Observable<Feedback> {
    const url = `${environment.url.feedBack}/generate/subscription`;

    return this.http.post<Feedback>(url, {});
  }

  /**
   * Метод проверяет, нужно ли собрать обратную связь о процессе создания или продлении подписки клиента
   * @param subscriptionId String
   * @returns Feedback
   */
  public generateSubscriptionCreationOrProlongationFeedback(subscriptionId: string): Observable<Feedback> {
    const url = `${environment.url.feedBack}/generate/subscription-creation-or-prolongation`;

    return this.http.post<Feedback>(url, { subscriptionId });
  }

  /**
   * Метод проверяет, нужно ли собрать обратную связь о процессе и ассортименте кастомизации блюд
   * @returns Feedback
   */
  public generateCustomizationFeedback(): Observable<Feedback> {
    const url = `${environment.url.feedBack}/generate/customization`;

    return this.http.post<Feedback>(url, {});
  }

  /**
   * Метод проверяет, нужно ли собрать обратную связь о доставке
   * @returns Feedback
   */
  public generateDeliveryFeedback(): Observable<Feedback> {
    const url = `${environment.url.feedBack}/generate/delivery`;

    return this.http.post<Feedback>(url, {});
  }

  /**
   * Метод сохраняет ответы обратной связи
   * @param feedbackId String
   * @param feedbackToSend FeedbackToSend
   * @returns void
   */
  public sendAnswers(feedbackId: string, feedbackToSend: FeedbackToSend): Observable<void> {
    const url = `${environment.url.feedBack}/${feedbackId}/answers`;

    return this.http.post<void>(url, feedbackToSend);
  }
}
