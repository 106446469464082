import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BindTokenReqBody, DeleteBindTokenReqBody, Notification, SendEventReqBody } from '@shared/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PushService {
  constructor(private http: HttpClient) {}

  public bindToken(body: BindTokenReqBody): Observable<void> {
    return this.http.post<void>(`${environment.url.pushNotification}/bind-token`, body);
  }

  public getNotifications(): Observable<Notification[]> {
    return this.http.get<any>(`${environment.url.pushNotification}`);
  }

  public deleteTokenBinding(body: DeleteBindTokenReqBody): Observable<void> {
    return this.http.post<void>(`${environment.url.pushNotification}/delete-token-binding`, body);
  }

  public sendEvent(body: SendEventReqBody): Observable<void> {
    return this.http.post<void>(`${environment.url.pushNotification}/events/click`, body);
  }

  public markAsRead(id: string): Observable<void> {
    return this.http.post<void>(`${environment.url.pushNotification}/events/click`, { PushMessageToClientId: id });
  }
}
