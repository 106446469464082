export enum ProgramTypesEnum {
  Sport = 'Sport',
  Fit = 'Fit',
  Life = 'Life',
  Veg = 'Veg',
}

export const ProgramTypesList = [ProgramTypesEnum.Sport, ProgramTypesEnum.Fit, ProgramTypesEnum.Life, ProgramTypesEnum.Veg];

export const ProgramTypeNames = {
  [ProgramTypesEnum.Sport]: 'Базовое',
  [ProgramTypesEnum.Fit]: 'Разнообразное',
  [ProgramTypesEnum.Life]: 'Премиум',
  [ProgramTypesEnum.Veg]: 'Вег',
};

export enum ProgramTypesIdsEnum {
  Fit,
  Life,
  Sport,
  Veg,
}

export enum ProgramDurationsEnum {
  oneDay = 'oneDay',
  twoDays = 'twoDays',
  threeDays = 'threeDays',
  oneWeek = 'oneWeek',
  twoWeeks = 'twoWeeks',
  threeWeeks = 'threeWeeks',
  fourWeeks = 'fourWeeks',
  twoMonth = 'twoMonth',
}

export const ProgramDurationInDays = {
  [ProgramDurationsEnum.oneDay]: 1,
  [ProgramDurationsEnum.twoDays]: 2,
  [ProgramDurationsEnum.oneWeek]: 5,
  [ProgramDurationsEnum.twoWeeks]: 10,
  [ProgramDurationsEnum.threeWeeks]: 15,
  [ProgramDurationsEnum.fourWeeks]: 20,
};

export const ProgramDurationNames = {
  [ProgramDurationsEnum.oneDay]: '1 день',
  [ProgramDurationsEnum.twoDays]: '2 дня',
  [ProgramDurationsEnum.threeDays]: '3 дня',
  [ProgramDurationsEnum.oneWeek]: '1 неделя',
  [ProgramDurationsEnum.twoWeeks]: '2 недели',
  [ProgramDurationsEnum.threeWeeks]: '3 недели',
  [ProgramDurationsEnum.fourWeeks]: '4 недели',
  [ProgramDurationsEnum.twoMonth]: '2 месяца',
};

export const ProgramDurationNamesMobile = {
  [ProgramDurationsEnum.oneDay]: '1 день',
  [ProgramDurationsEnum.twoDays]: '2 дня',
  [ProgramDurationsEnum.threeDays]: '3 дня',
  [ProgramDurationsEnum.oneWeek]: '1 нед.',
  [ProgramDurationsEnum.twoWeeks]: '2 нед.',
  [ProgramDurationsEnum.threeWeeks]: '3 нед.',
  [ProgramDurationsEnum.fourWeeks]: '4 нед.',
  [ProgramDurationsEnum.twoMonth]: '2 месяца',
};

export enum ProgramModesEnum {
  NoBreakfast = 'NoBreakfast',
  Full = 'Full',
  IdealDay = 'IdealDay',
  ExperimentalWeek = 'ExperimentalWeek',
}
