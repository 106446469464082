import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, first, tap } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';

import { STORIES_DATA } from '../stories-data.const';
import { Story, StorySlide } from '../stories.model';
import { StoryViewerComponent } from '../story-viewer/story-viewer.component';
import { SlideIdEnum, StorieIdEnum } from '../stories.enum';
import { FamilySubscriptionService } from '@modules/thanks/services/family-subscription.service';
import { ToastService } from '@shared/services';

@Component({
  selector: 'app-stories',
  templateUrl: './stories-nav.component.html',
  styleUrls: ['./stories-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoriesComponent {
  public storiesData$ = new BehaviorSubject(STORIES_DATA);

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private familySubscriptionService: FamilySubscriptionService,
    private toastService: ToastService,
  ) {}

  public async openStoryViewer(story: Story): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: StoryViewerComponent,
      componentProps: { slides: story.slides },
    });

    modal.present();

    const { data } = await modal.onWillDismiss();

    if (data.isShowed) {
      this.markShowedStory(story);
    }

    if (data.clickedSlide as StorySlide) {
      this.onClickSlide(data.clickedSlide);
    }
  }

  private markShowedStory(story: Story): void {
    Preferences.set({ key: 'stories_' + story.id, value: 'true' });

    const updatedData = STORIES_DATA.map(item =>
      item.id !== story.id
        ? item
        : {
            ...item,
            isShowed: true,
          },
    );

    this.storiesData$.next(updatedData);
  }

  private onClickSlide(slide: StorySlide): void {
    // Для слайда с оформлением заявки на вторую подписку нужно заменить текст на кнопке после клика
    if (slide.id === SlideIdEnum.PERSONAL_DISCOUNT_1) {
      if (slide.buttonText === 'Заявка оформлена') {
        return;
      }
      this.familySubscriptionService
        .setFamilySubscription()
        .pipe(
          first(),
          tap(() => {
            this.toastService.presentToastSuccess(
              'bottom',
              'Заявка на семейную подписку оформлена!',
              'В ближайшее время с Вами свяжется наш менеджер',
            );

            const updatedData = STORIES_DATA.map(story =>
              story.id !== StorieIdEnum.PERSONAL_DISCOUNTS
                ? story
                : {
                    ...story,
                    slides: story.slides.map(slide =>
                      slide.id === SlideIdEnum.PERSONAL_DISCOUNT_1 ? { ...slide, buttonText: 'Заявка оформлена' } : slide,
                    ),
                  },
            );

            this.storiesData$.next(updatedData);
          }),
        )
        .subscribe();
      return;
    }
    // ------

    const url = slide.buttonLink;

    if (!url) {
      return;
    }

    url.includes('http') ? (document.location.href = url) : this.router.navigateByUrl(url);
  }
}
