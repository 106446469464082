import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import Swiper from 'swiper';

import { StorySlide } from '../stories.model';

@Component({
  templateUrl: './story-viewer.component.html',
  styleUrls: ['./story-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoryViewerComponent {
  @Input() public slides: StorySlide[];

  @ViewChild('progress') set progressElement(progress: any) {
    if (progress) {
      progress = progress.nativeElement;

      progress.addEventListener('animationend', () => {
        this.nextStoryItem();
      });

      progress.addEventListener('webkitAnimationEnd', () => {
        this.nextStoryItem();
      });
    }
  }

  @ViewChild('slider') swiperRef: ElementRef | undefined;

  get swiper(): Swiper {
    return this.swiperRef?.nativeElement.swiper;
  }

  public activeSlideIndex = 0;
  public isPaused = false;

  constructor(private modalCtrl: ModalController) {}

  public onChangeSlide(): void {
    this.activeSlideIndex = this.swiper.activeIndex;
  }

  public nextStoryItem(): void {
    if (this.activeSlideIndex < this.slides.length - 1) {
      this.activeSlideIndex++;
      this.swiper.slideNext();
    } else {
      this.closeStoryViewer(true);
    }
  }

  public closeStoryViewer(isShowed = false): void {
    this.modalCtrl.dismiss({ isShowed });
  }

  public pauseStory(): void {
    this.isPaused = true;
  }

  public playStory(): void {
    this.isPaused = false;
  }

  public clickButton(clickedSlide: StorySlide): void {
    this.modalCtrl.dismiss({ clickedSlide });
  }
}
