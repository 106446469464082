<div
  class="wrapper"
  (touchstart)="pauseStory()"
  (touchend)="playStory()"
  (press)="pauseStory()"
  (pressup)="playStory()"
  (panstart)="pauseStory()"
  (panend)="playStory()"
>
  <div class="top-panel">
    <div class="progress-bar">
      <ng-container *ngFor="let slide of slides; let index = index">
        <div class="progress-item" [class.isPaused]="isPaused">
          <div *ngIf="activeSlideIndex === index" class="active" #progress></div>
        </div>
      </ng-container>
    </div>
    <ion-icon class="close" name="close-outline" (click)="closeStoryViewer()"></ion-icon>
  </div>

  <swiper-container #slider (click)="nextStoryItem()" (swiperslidechange)="onChangeSlide()">
    <swiper-slide *ngFor="let slide of slides" [style.backgroundColor]="slide.backgroundColor">
      <img *ngIf="slide.imageTop" [src]="slide.imageTop" />

      <div class="content" [class.noImageTop]="!slide.imageTop">
        <p class="title" [style.color]="slide?.textColor">{{ slide.title }}</p>
        <p *ngFor="let paragraph of slide.textParagraphs" [style.color]="slide?.textColor">{{ paragraph }}</p>
      </div>

      <div *ngIf="slide.imageMiddle" class="image-middle">
        <img [src]="slide.imageMiddle" />
      </div>

      <div class="block-bottom">
        <p *ngIf="slide.buttonTextAbove" class="textAbove" [style.color]="slide?.textColor">{{ slide.buttonTextAbove }}</p>

        <button *ngIf="slide.buttonText" (click)="clickButton(slide)">
          <app-icon *ngIf="slide.buttonIcon" icon="vk-white" class="button-icon icon"></app-icon>
          {{ slide.buttonText }}
        </button>

        <p *ngIf="slide.buttonTextBelow" class="textBelow" [style.color]="slide?.textColor">{{ slide.buttonTextBelow }}</p>
      </div>
    </swiper-slide>
  </swiper-container>
</div>
