import { ProgramViewAvailableData } from '@shared/models';
import { ProgramDurationsEnum, ProgramTypesEnum, DeliveryOptionsEnum, ProgramCaloriesEnum } from '@shared/enums';

const programsDishTypes = {
  [ProgramTypesEnum.Sport]: ['каши', 'омлеты', 'блюда из творога', 'горячее с гарниром', 'салаты'],
  [ProgramTypesEnum.Fit]: [
    'каши',
    'омлеты',
    'блюда из творога',
    'салаты',
    'роллы',
    'вок',
    'паста',
    'горячее с гарниром',
    'супы',
    'напитки',
    'десерты',
  ],
  [ProgramTypesEnum.Life]: [
    'каши',
    'омлеты',
    'блюда из творога',
    'салаты',
    'горячее с гарниром',
    'боулы',
    'роллы',
    'вок',
    'паста',
    'лазанья',
    'супы',
    'напитки',
    'десерты',
  ],
  [ProgramTypesEnum.Veg]: [
    'каши',
    'омлеты',
    'блюда из творога',
    'салаты',
    'горячее с гарниром',
    'роллы',
    'паста',
    'лазанья',
    'супы',
    'напитки',
    'десерты',
  ],
};

export const autoRenewalDiscount = 0;

export const ALL_PROGRAMS_VIEW_AVAILABLE_DATA: Record<string, ProgramViewAvailableData> = {
  [ProgramTypesEnum.Sport]: {
    id: ProgramTypesEnum.Sport,
    calorieOptions: [
      ProgramCaloriesEnum.cal1000,
      ProgramCaloriesEnum.cal1200,
      ProgramCaloriesEnum.cal1500,
      ProgramCaloriesEnum.cal2000,
      ProgramCaloriesEnum.cal2500,
    ],
    durationOptions: [
      {
        id: ProgramDurationsEnum.twoDays,
      },
      {
        id: ProgramDurationsEnum.threeDays,
      },
      {
        id: ProgramDurationsEnum.oneWeek,
        defaultDelivery: DeliveryOptionsEnum.sportWeekdays,
        extOptionsInfo: {
          autoRenew: {},
          eatOnSaturday: {
            customDelivery: DeliveryOptionsEnum.sportWeekdaysAndSaturday,
          },
          eatOnSaturdayAndSunday: {
            customDelivery: DeliveryOptionsEnum.sportAllWeek,
          },
        },
      },
      {
        id: ProgramDurationsEnum.twoWeeks,
        defaultDelivery: DeliveryOptionsEnum.sportWeekdays,
        extOptionsInfo: {
          eatOnSaturday: {
            customDelivery: DeliveryOptionsEnum.sportWeekdaysAndSaturday,
          },
          eatOnSaturdayAndSunday: {
            customDelivery: DeliveryOptionsEnum.sportAllWeek,
          },
        },
      },
      {
        id: ProgramDurationsEnum.threeWeeks,
        defaultDelivery: DeliveryOptionsEnum.sportWeekdays,
        extOptionsInfo: {
          eatOnSaturday: {
            customDelivery: DeliveryOptionsEnum.sportWeekdaysAndSaturday,
          },
          eatOnSaturdayAndSunday: {
            customDelivery: DeliveryOptionsEnum.sportAllWeek,
          },
        },
      },
      {
        id: ProgramDurationsEnum.fourWeeks,
        defaultDelivery: DeliveryOptionsEnum.sportWeekdays,
        extOptionsInfo: {
          eatOnSaturday: {
            customDelivery: DeliveryOptionsEnum.sportWeekdaysAndSaturday,
          },
          eatOnSaturdayAndSunday: {
            customDelivery: DeliveryOptionsEnum.sportAllWeek,
          },
        },
      },
      {
        id: ProgramDurationsEnum.twoMonth,
        defaultDelivery: DeliveryOptionsEnum.sportWeekdays,
        extOptionsInfo: {
          eatOnSaturday: {
            customDelivery: DeliveryOptionsEnum.sportWeekdaysAndSaturday,
          },
          eatOnSaturdayAndSunday: {
            customDelivery: DeliveryOptionsEnum.sportAllWeek,
          },
        },
      },
    ],
    dishTypesList: programsDishTypes[ProgramTypesEnum.Sport],
  },
  [ProgramTypesEnum.Fit]: {
    id: ProgramTypesEnum.Fit,
    calorieOptions: [ProgramCaloriesEnum.cal1200, ProgramCaloriesEnum.cal1500, ProgramCaloriesEnum.cal2000, ProgramCaloriesEnum.cal2500],
    durationOptions: [
      {
        id: ProgramDurationsEnum.twoDays,
      },
      {
        id: ProgramDurationsEnum.oneWeek,
        defaultDelivery: DeliveryOptionsEnum.fitWeekdays,
        extOptionsInfo: {
          autoRenew: {},
          noBreakfastAndDinner: {},
          eatOnSaturday: {
            customDelivery: DeliveryOptionsEnum.fitWeekdaysAndSaturday,
          },
          eatOnSaturdayAndSunday: {
            customDelivery: DeliveryOptionsEnum.fitAllWeek,
          },
        },
      },
      {
        id: ProgramDurationsEnum.twoWeeks,
        defaultDelivery: DeliveryOptionsEnum.fitWeekdays,
        extOptionsInfo: {
          noBreakfastAndDinner: {},
          eatOnSaturday: {
            customDelivery: DeliveryOptionsEnum.fitWeekdaysAndSaturday,
          },
          eatOnSaturdayAndSunday: {
            customDelivery: DeliveryOptionsEnum.fitAllWeek,
          },
        },
      },
      {
        id: ProgramDurationsEnum.threeWeeks,
        defaultDelivery: DeliveryOptionsEnum.fitWeekdays,
        extOptionsInfo: {
          noBreakfastAndDinner: {},
          eatOnSaturday: {
            customDelivery: DeliveryOptionsEnum.fitWeekdaysAndSaturday,
          },
          eatOnSaturdayAndSunday: {
            customDelivery: DeliveryOptionsEnum.fitAllWeek,
          },
        },
      },
      {
        id: ProgramDurationsEnum.fourWeeks,
        defaultDelivery: DeliveryOptionsEnum.fitWeekdays,
        extOptionsInfo: {
          noBreakfastAndDinner: {},
          eatOnSaturday: {
            customDelivery: DeliveryOptionsEnum.fitWeekdaysAndSaturday,
          },
          eatOnSaturdayAndSunday: {
            customDelivery: DeliveryOptionsEnum.fitAllWeek,
          },
        },
      },
      {
        id: ProgramDurationsEnum.twoMonth,
        defaultDelivery: DeliveryOptionsEnum.fitWeekdays,
        extOptionsInfo: {
          noBreakfastAndDinner: {},
          eatOnSaturday: {
            customDelivery: DeliveryOptionsEnum.fitWeekdaysAndSaturday,
          },
          eatOnSaturdayAndSunday: {
            customDelivery: DeliveryOptionsEnum.fitAllWeek,
          },
        },
      },
    ],
    dishTypesList: programsDishTypes[ProgramTypesEnum.Fit],
  },

  [ProgramTypesEnum.Life]: {
    id: ProgramTypesEnum.Life,
    calorieOptions: [ProgramCaloriesEnum.cal1200, ProgramCaloriesEnum.cal1500, ProgramCaloriesEnum.cal2000],
    durationOptions: [
      {
        id: ProgramDurationsEnum.twoDays,
      },
      {
        id: ProgramDurationsEnum.oneWeek,
        defaultDelivery: DeliveryOptionsEnum.lifeWeekdays,
        extOptionsInfo: {
          autoRenew: {},
          eatOnSaturday: {
            customDelivery: DeliveryOptionsEnum.lifeWeekdaysAndSaturday,
          },
          eatOnSaturdayAndSunday: {
            customDelivery: DeliveryOptionsEnum.lifeAllWeek,
          },
        },
      },
      {
        id: ProgramDurationsEnum.twoWeeks,
        defaultDelivery: DeliveryOptionsEnum.lifeWeekdays,
        extOptionsInfo: {
          eatOnSaturday: {
            customDelivery: DeliveryOptionsEnum.lifeWeekdaysAndSaturday,
          },
          eatOnSaturdayAndSunday: {
            customDelivery: DeliveryOptionsEnum.lifeAllWeek,
          },
        },
      },
      {
        id: ProgramDurationsEnum.threeWeeks,
        defaultDelivery: DeliveryOptionsEnum.lifeWeekdays,
        extOptionsInfo: {
          eatOnSaturday: {
            customDelivery: DeliveryOptionsEnum.lifeWeekdaysAndSaturday,
          },
          eatOnSaturdayAndSunday: {
            customDelivery: DeliveryOptionsEnum.lifeAllWeek,
          },
        },
      },
      {
        id: ProgramDurationsEnum.fourWeeks,
        defaultDelivery: DeliveryOptionsEnum.lifeWeekdays,
        extOptionsInfo: {
          eatOnSaturday: {
            customDelivery: DeliveryOptionsEnum.lifeWeekdaysAndSaturday,
          },
          eatOnSaturdayAndSunday: {
            customDelivery: DeliveryOptionsEnum.lifeAllWeek,
          },
        },
      },
      {
        id: ProgramDurationsEnum.twoMonth,
        defaultDelivery: DeliveryOptionsEnum.lifeWeekdays,
        extOptionsInfo: {
          eatOnSaturday: {
            customDelivery: DeliveryOptionsEnum.lifeWeekdaysAndSaturday,
          },
          eatOnSaturdayAndSunday: {
            customDelivery: DeliveryOptionsEnum.lifeAllWeek,
          },
        },
      },
    ],
    dishTypesList: programsDishTypes[ProgramTypesEnum.Life],
  },
};

export const getProgramViewAvailableData = (programType: ProgramTypesEnum) => ALL_PROGRAMS_VIEW_AVAILABLE_DATA[programType];
