<div class="container">
  <div class="inner-container">
    <div class="btn-back">
      <ion-spinner
        *ngIf="isLoading"
        class="icon-loading"
        name="dots"></ion-spinner>

      <app-icon
        *ngIf="!isLoading"
        (click)="onGoBack()"
        class="icon-back"
        icon="toolbar-left"></app-icon>
    </div>
    <div class="title">
      {{ title }}
    </div>
  </div>

</div>
