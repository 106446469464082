/* eslint-disable @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars-ts */

import { Input, Self, Component, ChangeDetectorRef, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormArray, NgControl } from '@angular/forms';

@Component({
  template: '',
})
export class BaseInputComponent<T> implements ControlValueAccessor {
  value: T;
  @Input() customMessage: string;
  @Input() label: string;
  @Input() inputMaxLength: string;
  @Input() placeholder: string;
  @Input() inputType = 'text';
  @Input() isRequired = false;
  @Input() placeholderFloat = false;
  @Input() showMaskTyped = true;
  @Input() customInputClass = '';
  @Input() mask: string;
  @Input() prefix: string;
  @Input() autocomplete: string;
  @Input() inputTabIndex: string;
  @Input() inputId: string;
  @Input() errorMessages: { [key: string]: string } = {
    required: 'Это поле обязательно',
  };

  @Output() public inputValue = new EventEmitter<T>();
  @Output() public inputBlur = new EventEmitter<T>();

  @ViewChild('input') input: ElementRef;
  @ViewChild('container') container: ElementRef;

  constructor(@Self() public ngControl: NgControl, protected cdr: ChangeDetectorRef) {
    ngControl.valueAccessor = this;
  }

  onInput(): void {
    if (!this.input.nativeElement) {
      return;
    }

    this.value = this.input.nativeElement.value;
    this.inputValue.emit(this.value);
  }

  onBlur(): void {
    this.inputBlur.emit(this.value);
  }

  get hasErrors(): boolean {
    return !!(this.ngControl.control && this.ngControl.control.touched && this.ngControl.control.errors);
  }

  get isDone(): boolean {
    return this.hasRequiredField(this.ngControl.control) && this.ngControl.valid;
  }

  get errorMessage(): string {
    const errors = Object.keys(this.ngControl.control.errors || {});
    const hasError = errors && errors.length;

    if (!hasError) {
      return '';
    }

    return errors.reduce((res: string, key: string) => {
      if (this.errorMessages[key] && !res) {
        res = this.errorMessages[key];
      }
      return res;
    }, '');
  }

  get hasValue(): boolean {
    if (typeof this.value === 'string') {
      return this.value && this.value.length > 0;
    }

    return !!this.value;
  }

  private hasRequiredField(control: AbstractControl): boolean {
    if (control.validator) {
      const validator = control.validator({} as AbstractControl);

      if (validator && validator.required) {
        return true;
      }
    }

    if ((control as FormArray).controls) {
      for (const controlName in (control as FormArray).controls) {
        if ((control as FormArray).controls[controlName]) {
          if (this.hasRequiredField((control as FormArray).controls[controlName])) {
            return true;
          }
        }
      }
    }

    return false;
  }

  // implementation of `ControlValueAccessor`
  writeValue(value: T): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {}

  registerOnTouched(fn: () => void): void {}

  setDisabledState(disabled: boolean): void {}
}
