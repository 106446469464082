import { Selector } from '@ngxs/store';
import { HttpErrorResponse } from '@angular/common/http';

import { PayCryptogramResponse, Card, SubscriptionCreationResp, PaymentSbpInfoResponse, PaymentDetails, ThanksData } from '@shared/models';
import { PaymentStateModel } from './payment.model';

export class PaymentSelectors {
  @Selector([state => state.payment])
  static state(state: PaymentStateModel): PaymentStateModel {
    return state;
  }

  @Selector([PaymentSelectors.state])
  static subscriptionCreationResp(state: PaymentStateModel): SubscriptionCreationResp {
    return state.subscriptionCreationResp;
  }

  @Selector([PaymentSelectors.state])
  static paymentDetails(state: PaymentStateModel): PaymentDetails {
    return state.paymentDetails;
  }

  @Selector([PaymentSelectors.state])
  static startedPaymentId(state: PaymentStateModel): string {
    return state.startedPaymentId;
  }

  @Selector([PaymentSelectors.state])
  static paymentSystemResp(state: PaymentStateModel): PayCryptogramResponse {
    return state.paymentSystemResp;
  }

  @Selector([PaymentSelectors.state])
  static paymentSystemErrorResp(state: PaymentStateModel): HttpErrorResponse {
    return state.paymentSystemErrorResp;
  }

  @Selector([PaymentSelectors.state])
  static errorMessage({ paymentSystemResp, paymentSystemErrorResp }: PaymentStateModel): string {
    return paymentSystemResp
      ? paymentSystemResp.message
      : paymentSystemErrorResp
      ? paymentSystemErrorResp?.error?.message || 'Что-то пошло не так... Пожалуйста, повторите действие'
      : '';
  }

  @Selector([PaymentSelectors.state])
  static cards(state: PaymentStateModel): Card[] {
    return state.cards;
  }

  @Selector([PaymentSelectors.state])
  static activeCardId(state: PaymentStateModel): string {
    return state.activeCardId;
  }

  @Selector([PaymentSelectors.state])
  static paymentSbpStatus(state: PaymentStateModel): 'Created' | 'Pending' | 'Completed' | 'Declined' {
    return state.paymentSbpStatus;
  }

  @Selector([PaymentSelectors.state])
  static sbpInfoResponse(state: PaymentStateModel): PaymentSbpInfoResponse {
    return state.sbpInfoResponse;
  }

  @Selector([PaymentSelectors.state])
  static thanksData(state: PaymentStateModel): ThanksData {
    return {
      text: state.thanksText,
      title: state.thanksTitle,
      btnText: state.thanksBtnText,
    };
  }
}
