<div class="wrapper">
  <div *ngIf="!isSkipped" class="tabs">
    <div class="tab" [class.active]="duration === ProgramDurationsEnum.fourWeeks" (click)="setDuration(ProgramDurationsEnum.fourWeeks)">
      4 недели<br /><span *ngIf="availableDiscounts | availableDiscount:ProgramDurationsEnum.fourWeeks as discount">-{{ discount }}%</span>
    </div>

    <div class="tab" [class.active]="duration === ProgramDurationsEnum.twoMonth" (click)="setDuration(ProgramDurationsEnum.twoMonth)">
      2 месяца<br /><span *ngIf="availableDiscounts | availableDiscount:ProgramDurationsEnum.twoMonth as discount">-{{ discount }}%</span>
    </div>
  </div>

  <div *ngIf="!isSkipped" class="payment-info">
    <div>
      <p class="text-16 text-bold">
        {{duration === ProgramDurationsEnum.fourWeeks ? '4 платежа' : '8 платежей'}} по {{ weekSplitPrice | customNumber: 'price' }} ₽
      </p>
      <p class="text-14 total">Общая сумма: {{ total | customNumber: 'price' }} ₽</p>
    </div>

    <div>
      <span (click)="openExternalLink('/assets/documents/split-sale.pdf')" class="text-12 text-thin link">Условия акции</span>
    </div>
  </div>

  <div class="payment-schedule" [class.two-month]="duration === ProgramDurationsEnum.twoMonth">
    <ng-container *ngIf="duration === ProgramDurationsEnum.fourWeeks; else twoMonth">
      <div class="payment-item active">
        <div class="date text-12 text-thin">Сегодня</div>
        <div class="price text-14 text-semibold">{{ weekSplitPrice | customNumber: 'price' }} ₽</div>
        <div class="line"></div>
      </div>

      <div class="payment-item">
        <div class="date text-12 text-thin">Через<br />1 неделю</div>
        <div class="price text-14 text-semibold">{{ weekSplitPrice | customNumber: 'price' }} ₽</div>
        <div class="line"></div>
      </div>

      <div class="payment-item">
        <div class="date text-12 text-thin">Через<br />2 недели</div>
        <div class="price text-14 text-semibold">{{ weekSplitPrice | customNumber: 'price' }} ₽</div>
        <div class="line"></div>
      </div>

      <div class="payment-item">
        <div class="date text-12 text-thin">Через<br />3 недели</div>
        <div class="price text-14 text-semibold">{{ weekSplitPrice | customNumber: 'price' }} ₽</div>
        <div class="line"></div>
      </div>
    </ng-container>

    <ng-template #twoMonth>
      <div class="payment-item active">
        <div class="date text-12 text-thin">Сегодня</div>
        <div class="price text-14 text-semibold">{{ weekSplitPrice | customNumber: 'price' }} ₽</div>
        <div class="line"></div>
      </div>

      <div class="payment-item">
        <div class="date text-12 text-thin">Далее 7 платежей еженедельно</div>
        <div class="price text-14 text-semibold">по {{ weekSplitPrice | customNumber: 'price' }} ₽</div>
        <div class="line"></div>
      </div>
    </ng-template>
  </div>
</div>
