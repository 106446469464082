export enum LoadingTagEnum {
  updatingProgram = 'updatingProgram',
  updatingProgramPrice = 'updatingProgramPrice',
  ingredientsExclusion = 'ingredientsExclusion',
  checkingPromocode = 'checkingPromocode',
  creatingSubscription = 'creatingSubscription',
  loadSubscriptionInfo = 'loadSubscriptionInfo',
  renewSubscriptionInfo = 'renewSubscriptionInfo',
  loadNotifications = 'loadNotifications',
}
