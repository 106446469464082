import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Feedback, FeedbackToSend } from '@modules/client-menu/models';

@Component({
  selector: 'app-rating-modal',
  templateUrl: './rating-modal.component.html',
  styleUrls: ['./rating-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingModalComponent {
  @Input() public feedback: Feedback;
  @Input() public isFeedbackSent: boolean;

  @Output() public setRating = new EventEmitter();
  @Output() public sendFeedback = new EventEmitter<{ feedbackId: string; feedbackToSend: FeedbackToSend }>();
  @Output() public close = new EventEmitter<boolean>();

  /**
   * Количество звёзд в отзыве
   */
  public rating = 0;

  /**
   * Вопрос, который задаётся клиенту после выбора звёзд
   */
  public question = '';

  /**
   * Список возможных готовых ответов
   */
  public answers = [];

  /**
   * Список выбранных ответов
   */
  public choosenAnswers = new Set<string>();

  /**
   * Комментарий клиента
   */
  public comment = '';

  public chooseRating(rating: number, { questions, comment }: Feedback): void {
    // Присваиваем значения, выбираем список доступных вариантов ответа
    this.rating = rating;
    this.question = questions[rating - 1]?.question || '';
    this.answers = questions[rating - 1]?.answers || [];
    this.comment = comment;

    // Очищаем предыдущий список возможных вариантов ответов, т.к клиент может изменить кол-во звёзд
    this.choosenAnswers.clear();

    this.setRating.emit();
  }

  /**
   * Метод добавляет вариант ответа или удаляет его, если тот уже есть в списке выбранных
   * @param answer
   */
  public setChoosenAnswers(answer: string): void {
    this.choosenAnswers.has(answer) ? this.choosenAnswers.delete(answer) : this.choosenAnswers.add(answer);
  }

  public onCompleteFeedback(): void {
    const feedbackToSend: FeedbackToSend = {
      rating: this.rating,
      answers: Array.from(this.choosenAnswers),
      comment: this.comment || '',
      isFinal: true,
    };

    const feedbackId = this.feedback.feedbackId;

    this.sendFeedback.emit({ feedbackId, feedbackToSend });
  }

  public closeModal(): void {
    this.close.emit();
  }

  public showBanner(): void {
    this.close.emit(true);
  }
}
