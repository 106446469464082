import { CurrentProgram } from '@shared/models/';
import { ProgramDurationsEnum, ProgramTypesEnum, ProgramCaloriesEnum } from '@shared/enums';

export const DefaultProgramConfig: Partial<CurrentProgram> = {
  id: ProgramTypesEnum.Fit,
  dailyCalories: ProgramCaloriesEnum.cal1200,
  durationId: ProgramDurationsEnum.oneWeek,
  extOptions: {
    noBreakfastAndDinner: false,
    eatOnSaturday: false,
    eatOnSaturdayAndSunday: false,
    autoRenew: false,
  }
};
