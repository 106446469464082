import { Pipe, PipeTransform } from '@angular/core';

import { ProgramTypesEnum, ProgramTypeNames } from '@shared/enums';

@Pipe({
  name: 'programName',
})
export class ProgramNamePipe implements PipeTransform {
  transform(value: ProgramTypesEnum | string): string {
    if (!value) {
      return '';
    }

    return ProgramTypeNames[value] || '';
  }
}
