import { StateContext } from '@ngxs/store';

import { ClientMenuDish } from '@modules/client-menu/models';
import { deepClone } from '@shared/utils';
import { ClientMenuStateModel, ReplacementDiffDishes } from './client-menu.model';
import { ProgramTypesEnum, ProgramDurationsEnum } from '@shared/enums';
import { ClientSubscriptionDetails, CurrentProgram } from '@shared/models';

/**
 *
 * @param ctx
 * @param currentDish текущее блюдо в меню
 * @param newDish блюдо, которое должно заменить текущее блюдо
 * @param isBackward true, если происходит обратная замена блюда (отмена замены)
 * @returns void
 */
export function replaceDishInActiveSubscription(
  ctx: StateContext<ClientMenuStateModel>,
  currentDish: ClientMenuDish,
  newDish: ClientMenuDish,
  isBackward = false,
): void {
  const { activeSubscriptionDetails, activePackage } = ctx.getState();

  if (!activeSubscriptionDetails && !activePackage) {
    return;
  }

  const changedSubscription = deepClone(activeSubscriptionDetails);
  const currentPackage = deepClone(activePackage);
  let updatedMenuItems: ClientMenuDish[];

  if (currentDish !== null) {
    updatedMenuItems = activePackage.menuItems.map(item =>
      item.id === currentDish.id && Boolean(item.shouldBeConfirmed) === isBackward ? newDish : item,
    );
  } else {
    updatedMenuItems = activePackage.menuItems.concat([newDish]);
  }

  currentPackage.menuItems = updatedMenuItems;
  const updatedPackageItems = changedSubscription.packageItems.map(p => (p.packageId === currentPackage.packageId ? currentPackage : p));
  changedSubscription.packageItems = updatedPackageItems;

  ctx.patchState({
    activeSubscriptionDetails: changedSubscription,
    activePackage: currentPackage,
  });
}

export function convertParamsIntoSetProgram(
  menuType: ProgramTypesEnum,
  weeksLength: '1' | '2' | '4',
): { id?: ProgramTypesEnum; durationId?: ProgramDurationsEnum } {
  const partial: Partial<CurrentProgram> = {};
  const type = formatedString(menuType) as ProgramTypesEnum;

  if (
    Object.values(ProgramTypesEnum)
      .map(val => val)
      .includes(type)
  ) {
    partial.id = type;
  }

  if (['1', '2', '4'].includes(weeksLength)) {
    const durationIds = {
      '1': ProgramDurationsEnum.oneWeek,
      '2': ProgramDurationsEnum.twoWeeks,
      '4': ProgramDurationsEnum.fourWeeks,
    };

    partial.durationId = durationIds[weeksLength];
  }

  return partial;
}

export function removeDishFromChanges(ctx: StateContext<ClientMenuStateModel>, dishToRemove: ClientMenuDish): ReplacementDiffDishes[] {
  const { changedDish, activeSubscriptionDetails } = ctx.getState();
  const activeSubscriptionChanges = changedDish[activeSubscriptionDetails.subscriptionId];

  let { withOriginalDish, withoutOriginalDish } = activeSubscriptionChanges.reduce(
    (result, dish) => {
      if (dish.originalDish) {
        result.withOriginalDish.push(dish);
      } else {
        result.withoutOriginalDish.push(dish);
      }
      return result;
    },
    { withOriginalDish: [], withoutOriginalDish: [] },
  );

  if (dishToRemove.originalUuid && dishToRemove.originalUuid !== null) {
    withOriginalDish = withOriginalDish.filter(change => change.originalDish.id !== dishToRemove.originalUuid);
  } else {
    const indexToRemove = withoutOriginalDish.findIndex(change => change.newDish.id === dishToRemove.id);
    if (indexToRemove !== -1) {
      withoutOriginalDish.splice(indexToRemove, 1);
    }
  }

  const updatedActiveSubscriptionChanges = [...withOriginalDish, ...withoutOriginalDish];

  return updatedActiveSubscriptionChanges;
}

export function removeDishFromSubscription(
  ctx: StateContext<ClientMenuStateModel>,
  changedDishesFromStore: ReplacementDiffDishes[],
  dishToRemove: ClientMenuDish,
): ClientSubscriptionDetails {
  const { activePackage, activeSubscriptionDetails } = ctx.getState();

  const changedSubscription = deepClone(activeSubscriptionDetails);
  const currentPackage = deepClone(activePackage);

  const packageToUpdate = changedSubscription.packageItems.map(packageItem =>
    packageItem.packageId === currentPackage.packageId ? currentPackage : packageItem,
  );
  if (!packageToUpdate) {
    return changedSubscription;
  }
  changedSubscription.packageItems = packageToUpdate;

  if (dishToRemove.originalUuid && dishToRemove.originalUuid !== null) {
    const dishToReplaceOriginal = changedDishesFromStore.find(d => d.originalDish?.id === dishToRemove.originalUuid);
    currentPackage.menuItems = currentPackage.menuItems.map(item =>
      item.originalUuid === dishToReplaceOriginal.originalDish?.id ? dishToReplaceOriginal.originalDish : item,
    );
  } else {
    const removeableItem = currentPackage.menuItems.find(d => d.isAdded && d.id === dishToRemove.id);
    currentPackage.menuItems = currentPackage.menuItems.filter(d => d !== removeableItem);
  }

  ctx.patchState({
    activeSubscriptionDetails: changedSubscription,
    activePackage: currentPackage,
  });

  return changedSubscription;
}

/** Метод меняет первую букву на заглавную */
export function formatedString(menuType: string): string {
  if (!menuType) {
    return '';
  }

  return menuType.charAt(0).toUpperCase() + menuType.slice(1);
}
