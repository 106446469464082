<swiper-container #swiper [pagination]="true" [speed]="400" (swiperslidechange)="checkLastSlide()">
  <swiper-slide>
    <div class="image type-1"></div>

    <div class="content">
      <div class="container">
        <div class="title">Есть, а не готовить</div>
        <div class="text">
          Готовая еда — уже в холодильнике. <br />
          Просто разогрейте
        </div>
        <div class="buttons">
          <button (click)="next()" class="btn-base btn-next on-activatable">Кайф</button>

          <a class="link-base link-skip" (click)="start()">Пропустить</a>
        </div>
      </div>
    </div>
  </swiper-slide>
  <swiper-slide>
    <div class="image type-2"></div>

    <div class="content">
      <div class="container">
        <div class="title">Полезно и вкусно</div>
        <div class="text">Скучная курица с гречкой — больше не синоним правильного питания</div>
        <div class="buttons">
          <button (click)="next()" class="btn-base btn-next on-activatable">Супер</button>

          <a class="link-base link-skip" (click)="start()">Пропустить</a>
        </div>
      </div>
    </div>
  </swiper-slide>
  <swiper-slide>
    <div class="image type-3"></div>

    <div class="content">
      <div class="container">
        <div class="title">Удобная доставка</div>
        <div class="text">Курьер привезет еду в течение интервала от&nbsp;30&nbsp;минут до 2 часов</div>
        <div class="buttons">
          <button (click)="next()" class="btn-base btn-next on-activatable">Покажите еду</button>
        </div>
      </div>
    </div>
  </swiper-slide>
</swiper-container>
