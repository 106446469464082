<div
  [appTooltip]="getTooltipText()"
  [isTooltipDisabled]="isTooltipDisabled()"
  [ngClass]="{'full-width': isFromDetailsModal}"
  class="tooltip-wrapper"
  delay="100"
>
  <!-- Single button that changes dynamically based on conditions -->
  <button
    *ngIf="[dish, package] | f: shouldShowButton"
    class="text-btn"
    [ngClass]="isFromDetailsModal | f: getButtonClass"
    [disabled]="[dish, package, isFromDetailsModal, isFromAdditionModal] | f: isButtonDisabled"
    (click)="handleButtonClick()"
  >
    {{ [dish, package, isFromDetailsModal, isFromAdditionModal] | f: getButtonText }}
  </button>
</div>

<ion-modal [isOpen]="isModalOpen" (willDismiss)="onWillDismiss($event)">
  <ng-template>
    <app-top-toolbar title="Замена для блюда" (goBack)="closeModal()"></app-top-toolbar>
    <div class="modal-sorting">
      <app-icon icon="sort" class="sort-icon" (click)="showSortDropdown()"></app-icon>
      <div class="dropdown header-dropdown-menu" [class.shown]="isShownSortDropdown">
        <p class="dropdown-title">Как сортировать блюда?</p>
        <div class="dropdown-options">
          <app-radio-toggler
            class="save-card-toggle"
            [value]="filterMethod === 'filterByCcal'"
            (changeValue)="selectFilterMethod('filterByCcal')"
            >По разнице ккал</app-radio-toggler
          >
          <app-radio-toggler
            class="save-card-toggle"
            [value]="filterMethod === 'filterByTitle'"
            (changeValue)="selectFilterMethod('filterByTitle')"
            >По алфавиту</app-radio-toggler
          >
          <app-radio-toggler
            class="save-card-toggle"
            [value]="filterMethod === 'filterByPrice'"
            (changeValue)="selectFilterMethod('filterByPrice')"
            >По цене</app-radio-toggler
          >
        </div>

        <button class="btn btn--orange sort-btn" (click)="sortDishes()">Показать</button>
      </div>
    </div>

    <ion-content class="ion-padding">
      <div class="old-dish">
        <div class="old-dish-info">{{ dish.grams | customNumber: 'weight'}} г/ {{ dish.caloricity | customNumber: 'calories' }} ккал</div>
        <div class="old-dish-title">{{dish.title}}</div>
      </div>

      <div class="loading" *ngIf="isLoading$ | async; else contentBlock">
        <div class="loading-content">
          <p class="msg text--18">Загружаем блюда</p>
          <div class="dot-flashing"></div>
        </div>
      </div>

      <ng-template #contentBlock>
        <ng-container *ngIf="sameTypeDishes?.length || otherDishes?.length; else noDishes">
          <div class="list">
            <ng-container *ngFor="let dish of sameTypeDishes">
              <app-dish-item-replaced [dish]="dish" [package]="package" (selectDish)="onSelectDish($event)" [isFromSelectDishModal]="true">
              </app-dish-item-replaced>
            </ng-container>
          </div>

          <ng-container *ngIf="otherDishes?.length">
            <h2 class="other-dishes-title">Варианты из других приемов питания</h2>
            <div class="list">
              <ng-container *ngFor="let dish of otherDishes">
                <app-dish-item-replaced
                  [dish]="dish"
                  [package]="package"
                  (selectDish)="onSelectDish($event)"
                  [isFromSelectDishModal]="true"
                >
                </app-dish-item-replaced>
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="retailDishes?.length">
            <h2 class="other-dishes-title">Дополнительно</h2>
            <div class="list">
              <ng-container *ngFor="let dish of retailDishes">
                <app-dish-item-replaced
                  [dish]="dish"
                  [package]="package"
                  (selectDish)="onSelectDish($event)"
                  [isFromSelectDishModal]="true"
                >
                </app-dish-item-replaced>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #noDishes>
          <div class="text">Нет доступных блюд для замены</div>
        </ng-template>
      </ng-template>
    </ion-content>
  </ng-template>
</ion-modal>
