import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ClientMenuSelectors, ReplacementDiffDishes } from '@store/client-menu';
import { NgOnDestroyService } from '@shared/services';
import { getDatesFromPackages, onlyUnique } from '@shared/utils';
import { ModalController } from '@ionic/angular';
import { ClientSubscriptionPackage } from '@shared/models';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
  providers: [NgOnDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationModalComponent implements OnInit {
  @Input() isImportant = false;

  public customizationPrice$: Observable<number> = inject(Store).select(ClientMenuSelectors.customizationPrice);
  public activeSubscriptionDetails = inject(Store).selectSnapshot(ClientMenuSelectors.subscriptionDetails);

  public packagesDates$: Observable<string>;

  constructor(private store: Store, private modalCtrl: ModalController) {}

  ngOnInit(): void {
    this.packagesDates$ = this.store
      .select(ClientMenuSelectors.replacementsInSubscription)
      .pipe(map((data: ReplacementDiffDishes[]) => this.getDates(data)));
  }

  public cancel(): void {
    this.modalCtrl.dismiss(null, 'erase');
  }

  public confirm(): void {
    this.modalCtrl.dismiss(true, 'confirm');
  }

  private getDates(replacements: ReplacementDiffDishes[]): string {
    const packages = this.getChangedPackages(replacements);

    return getDatesFromPackages(packages);
  }

  private getChangedPackages(data: ReplacementDiffDishes[]): ClientSubscriptionPackage[] {
    return data
      .map(item => item.packageId)
      .filter(onlyUnique)
      .map(packageId => this.activeSubscriptionDetails.packageItems.find(item => item.packageId === packageId))
      .filter(Boolean);
  }
}
