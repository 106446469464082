<ng-container
  *ngIf="{
  details: activeSubscriptionDetails$ | async,
  didPageFail: didPageFail$ | async,
  activePackageId: activePackageId$ | async
} as data"
>
  <div class="wrapper" *ngIf="!data.didPageFail && data.details; else skeleton">
    <div class="container container-list" #list>
      <div class="inner">
        <app-calendar-item
          *ngFor="let item of dates"
          [data]="item"
          [dates]="dates"
          [menuType]="data.details?.menuType"
          (select)="onSelectPackage(item, data.details, data.activePackageId)"
          [isCustomized]="item.packageId | f:isCustomizePackage:[data.details?.packageItems]"
          [activePackageId]="data.activePackageId"
          class="list-item"
        ></app-calendar-item>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #skeleton>
  <div class="container container-list">
    <div class="inner">
      <ion-skeleton-text [animated]="true" class="skeleton-calendar-item"></ion-skeleton-text>
      <ion-skeleton-text [animated]="true" class="skeleton-calendar-item"></ion-skeleton-text>
      <ion-skeleton-text [animated]="true" class="skeleton-calendar-item"></ion-skeleton-text>
      <ion-skeleton-text [animated]="true" class="skeleton-calendar-item"></ion-skeleton-text>
      <ion-skeleton-text [animated]="true" class="skeleton-calendar-item"></ion-skeleton-text>
      <ion-skeleton-text [animated]="true" class="skeleton-calendar-item"></ion-skeleton-text>
    </div>
  </div>
</ng-template>
