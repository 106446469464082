/* eslint-disable @typescript-eslint/no-useless-constructor */

import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgControl } from '@angular/forms';
import { BaseInputComponent } from '../base-controls';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent extends BaseInputComponent<string> {

  @Input() cloudpaymentsField: string;
  @Input() inputmode = '';
  @Input() pattern = '';
  @Input() theme: 'flat' | 'flat-big' | 'rounded'  = 'rounded';
  @Input() showArrow = false;
  @Input() isLoading: boolean;

  constructor(
    ngControl: NgControl,
    cdr: ChangeDetectorRef
  ) {
    super(ngControl, cdr);
  }

  public onFocus(event: FocusEvent): void {
    if (this.mask) {
      this.updateCursorPosition(event.target as HTMLInputElement);
    }
  }

  private updateCursorPosition(input: HTMLInputElement): void {
    setTimeout(() => {
      const firstEmptyPosition = /[_]/i.exec(input.value)?.index;

      if (firstEmptyPosition !== undefined) {
        input.selectionStart = firstEmptyPosition;
        input.selectionEnd = firstEmptyPosition;
      }
    }, 0);
  }

}
