import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { environment } from 'src/environments/environment';
import { ProgramSelectors } from '@store/program/program.selectors';
import { AuthSelectors } from '@store/auth';
import { CommonHelper } from '@shared/utils';
import {
  CurrentProgram,
  DeliveryIntervalResp,
  GetDeliveryScheduleResponse,
  SubscriptionCreationResp,
  SubscriptionInfo,
  SubscriptionPriceResponse,
  CreateSubscriptionModel,
  AvailableMovingDays,
  AvailableDiscount,
  AvailableDiscountParams,
} from '@shared/models';
import { CacheTypeEnum, ProgramModesEnum, ProgramTypesEnum } from '@shared/enums';
import { CacheService } from '../common';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(private commonHelper: CommonHelper, private http: HttpClient, private store: Store, private cacheService: CacheService) {}

  public createSubscription(model: CreateSubscriptionModel): Observable<SubscriptionCreationResp> {
    const url = environment.url.subscription;

    this.cacheService.deleteAllForType(CacheTypeEnum.subscription);
    return this.http.post<SubscriptionCreationResp>(url, model);
  }

  public deleteSubscription(subscriptionId: string): Observable<void> {
    return this.http.delete<void>(`${environment.url.subscription}/${subscriptionId}`);
  }

  // Возвращает массив доступных дат
  // Используется при оформлениии подписки
  public getAvailableStartDaysOnMonth(
    programTypeId: ProgramTypesEnum,
    allowSaturdays: boolean,
    mode = ProgramModesEnum.Full,
    days: number = null,
    isProstoeda = false,
    size = 0,
  ): Observable<string[]> {
    let url = !isProstoeda
      ? environment.url.availableStartDaysOnMonth +
        `?firstDate=${this.commonHelper.getIsoCurrentDateStrWithTime()}` +
        `&menuType=${programTypeId}` +
        `&allowSaturdays=${allowSaturdays}` +
        `&mode=${mode}`
      : environment.url.availableStartDaysOnMonthProstoeda +
        `?firstDate=${this.commonHelper.getIsoCurrentDateStrWithTime()}` +
        `&size=${size}`;

    if (days !== null) {
      url += `&days=${days}`;
    }

    return this.http.get<string[]>(url);
  }

  // Возвращает массив доступных дат
  // Используется при оформлениии подписки
  public getAvailableStartDateAfterLastPackage(
    programTypeId: ProgramTypesEnum,
    allowSaturdays: boolean,
    mode = ProgramModesEnum.Full,
    days: number = null,
    isProstoeda = false,
    size = 0,
  ): Observable<string> {
    let url = !isProstoeda
      ? environment.url.availableStartDateAfterLastPackage +
        `?menuType=${programTypeId}` +
        `&allowSaturdays=${allowSaturdays}` +
        `&mode=${mode}`
      : environment.url.availableStartDateAfterLastPackageProstoeda +
        `?menuType=${programTypeId}` +
        `&allowSaturdays=${allowSaturdays}` +
        `&mode=${mode}` +
        `&size=${size}`;

    if (days !== null) {
      url += `&days=${days}`;
    }

    return this.http.get<string>(url);
  }

  /**
   * Возвращает график доставки еды. Используется при оформлениии подписки.
   * Используется метод POST - на бэке должны исправить на GET
   *
   * @param programDurationInDays number
   * @param programTypeId ProgramTypesEnum
   * @param startDeliveryDay string
   * @returns Observable<GetDeliveryScheduleResponse>
   */
  public getDeliverySchedule(
    programDurationInDays: number,
    programTypeId: ProgramTypesEnum,
    startDeliveryDay: string,
    dailyCalories: number,
  ): Observable<GetDeliveryScheduleResponse> {
    const url = environment.url.deliverySchedule;
    const model = {
      daysCount: programDurationInDays,
      menuType: programTypeId,
      startDate: this.commonHelper.getCurrentDateObjWithoutTime(new Date(startDeliveryDay)),
      size: dailyCalories,
    };

    return this.http.post<GetDeliveryScheduleResponse>(url, model);
  }

  /**
   * Используется при оформлениии подписки
   *
   * @param programTypeId
   * @returns Observable<DeliveryIntervalResp>
   */
  public getDeliveryInterval(programTypeId: ProgramTypesEnum): Observable<DeliveryIntervalResp> {
    const url = `${environment.url.deliveryInterval}?newSubscriptionMenuType=${programTypeId}`;

    return this.http.get<DeliveryIntervalResp>(url);
  }

  public fetchSubscriptionPrice(currentProgram: CurrentProgram, promocode: string): Observable<SubscriptionPriceResponse> {
    const isAutoRenewalEnabled: boolean = Boolean(currentProgram?.extOptions?.autoRenew);
    const noBreakfast: boolean = Boolean(currentProgram?.extOptions?.noBreakfastAndDinner);

    const isAuthorized = this.store.selectSnapshot(AuthSelectors.isAuthorized);
    const startDeliveryDay = this.store.selectSnapshot(ProgramSelectors.startDeliveryDay);
    const mode = noBreakfast ? ProgramModesEnum.NoBreakfast : ProgramModesEnum.Full;
    const startDate = isAuthorized && startDeliveryDay ? startDeliveryDay : this.startOfDay();
    const requestParams = {
      promocode,
      mode,
      startDate,
      size: currentProgram.dailyCalories.toString(),
      menuType: currentProgram.id,
      daysCount: currentProgram.durationInDays.toString(),
      autoRenewal: isAutoRenewalEnabled.toString(),
      isSplittable: currentProgram.isSplittable,
    };
    const cacheKey = this.cacheService.generateKey({ ...requestParams, isAuthorized });

    if (this.cacheService.has(cacheKey, CacheTypeEnum.subscription)) {
      return of(this.cacheService.get<SubscriptionPriceResponse>(cacheKey, CacheTypeEnum.subscription));
    }

    return this.getSubscriptionPrice(requestParams).pipe(
      tap((response: SubscriptionPriceResponse) => this.cacheService.set(cacheKey, response, CacheTypeEnum.subscription)),
    );
  }

  private getSubscriptionPrice(requestParams): Observable<SubscriptionPriceResponse> {
    const params = new HttpParams({
      fromObject: requestParams,
    });

    return this.http.get<SubscriptionPriceResponse>(environment.url.subscriptionPrice, { params });
  }

  /**
   * Возвращает список доступных скидок по длительностям
   */
  public getAvailableDiscount(params: AvailableDiscountParams): Observable<AvailableDiscount[]> {
    return this.http.post<AvailableDiscount[]>(environment.url.availableDiscount, { ...params });
  }

  /**
   * Возвращает информацию о подписке по идентификатору платежа
   * @param paymentId уникальный идентификатор платежа
   */
  public fetchSubscriptionInfoByPayment(paymentId: string): Observable<SubscriptionInfo> {
    const url = environment.url.subscriptionInfoByPayment;

    return this.http.get<SubscriptionInfo>(`${url}?paymentId=${paymentId}`);
  }

  /**
   * Возвращает информацию о подписке по идентификатору подписки
   * @param subscriptionId уникальный идентификатор подписки
   */
  public fetchSubscriptionInfoById(subscriptionId: string): Observable<SubscriptionInfo> {
    const url = environment.url.subscriptionInfoById;

    return this.http.get<SubscriptionInfo>(`${url}?subscriptionId=${subscriptionId}`);
  }

  public removeFirstUnpaidSubscription(): Observable<unknown> {
    const url = environment.url.deleteFirstUnpaid;

    return this.http.post<unknown>(url, {});
  }

  /**
   * Возвращает доступные дни для переноса на заданный месяц
   * @param dayToMove день, который хотим перенести
   * @param subscriptionId уникальный идентификатор подписки
   *
   * @returns Observable<AvailableMovingDays>
   */
  public fetchAvailableMovingDays(dayToMove: string, subscriptionId: string): Observable<AvailableMovingDays> {
    const params = new HttpParams({
      fromObject: {
        movingDate: dayToMove,
        subscriptionId,
      },
    });

    return this.http.get<AvailableMovingDays>(environment.url.availableMovingDays, { params });
  }

  public startOfDay(): string {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    return dayjs().tz('Europe/Moscow').add(2, 'day').hour(0).minute(0).second(0).millisecond(0).utc().format();
  }
}
