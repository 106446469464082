import { Injectable } from '@angular/core';
import { filter, tap } from 'rxjs/operators';
import { State, Action, StateContext, Store } from '@ngxs/store';

import { InfoApiService } from '@shared/services';
import { GlobalStateModel } from './global.model';
import { HandleRouterEvents, LoadMinimumPrices } from './global.actions';
import { Router, NavigationEnd } from '@angular/router';
import { LoadSubscriptions, LoadNotifications, LoadActiveSubscription } from '@store/client-menu';
import { Observable } from 'rxjs';
import { RenewSubscriptionById } from '@store/program';

@State<GlobalStateModel>({
  name: 'global',
  defaults: {
    programMinimumPrices: {},
  },
})
@Injectable()
export class GlobalState {
  constructor(private infoApiService: InfoApiService, private router: Router, private store: Store) {}

  async ngxsOnInit({ dispatch }: StateContext<GlobalStateModel>): Promise<void> {
    dispatch([new HandleRouterEvents()]);
  }

  @Action(LoadMinimumPrices)
  loadMinimumPrices(ctx: StateContext<GlobalStateModel>) {
    return this.infoApiService.getMinimumPrices().pipe(
      tap(response => {
        const programMinimumPrices = response.reduce((res, item) => {
          res[item.menuType] = item.tariff;
          return res;
        }, {});

        ctx.patchState({
          programMinimumPrices,
        });
      }),
    );
  }

  @Action(HandleRouterEvents)
  handleRouterEvenets(): Observable<NavigationEnd> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      tap<NavigationEnd>(({ url }) => {
        const urlTree = this.router.parseUrl(url);
        const subscriptionId = urlTree.queryParams['subscriptionId'];
        const fromSubscription = urlTree.queryParams['fromSubscription'];
        const date = urlTree.queryParams['date'];

        switch (true) {
          case url === '/cabinet/subscriptions':
            this.store.dispatch(new LoadSubscriptions());
            break;
          case url === '/cabinet/notifications':
            this.store.dispatch(new LoadNotifications());
            break;
          case url.includes('/cabinet/create/program'):
            if (fromSubscription) {
              this.store.dispatch(new RenewSubscriptionById(fromSubscription));
            }

            break;
          case url.includes('/cabinet/menu'):
            subscriptionId
              ? this.store.dispatch(new LoadActiveSubscription(subscriptionId, { date }))
              : setTimeout(() => {
                  // Костыль!
                  // Это время список подписок уже точно будет получен,
                  // поэтому активной подпиской станет первая из списка
                  this.store.dispatch(new LoadActiveSubscription());
                }, 600);
            break;
          default:
            break;
        }
      }),
    );
  }
}
