import { IconComponent } from './icon';
import { TextInputComponent } from './text-input';
import { TogglerComponent } from './toggler';
import { AddressInputComponent } from './address-input';
import { ModalLayoutComponent } from './modal-layout';
import { RadioTogglerComponent } from './radio-toggler';
import { NoConnectionComponent } from './no-connection';
import { TopToolbarComponent } from './top-toolbar';
import { SubscriptionItemComponent } from './subscription-item';
import { DeliveryCalendarComponent } from './delivery-calendar';
import { PaymentSplitComponent } from './payment-split';
import { TextIonInputComponent } from './text-ion-input';
import { RatingModalComponent } from './rating-modal';
import { RatingComponent } from './rating';

export const components = [
  IconComponent,
  TextInputComponent,
  TogglerComponent,
  AddressInputComponent,
  ModalLayoutComponent,
  RadioTogglerComponent,
  TopToolbarComponent,
  SubscriptionItemComponent,
  DeliveryCalendarComponent,
  PaymentSplitComponent,
  TextIonInputComponent,
  RatingModalComponent,
  RatingComponent,
];

export { NoConnectionComponent, DeliveryCalendarComponent, IconComponent };
