import { ProgramModesEnum } from '@shared/enums';
import { ExtOptions } from '@shared/models';

export function getPackageViewId(id: string): string {
  return `package-${id}`;
}

export function trackByFn(index, item): string {
  return item?.id || `${index}`;
}

function getEatOptions(daysCount: number): Partial<ExtOptions> {
  const eatOnSaturday = daysCount % 6 === 0;
  const eatOnSaturdayAndSunday = daysCount % 7 === 0;

  return !daysCount
    ? {
      eatOnSaturday: false,
      eatOnSaturdayAndSunday: false,
    }
    : {
      eatOnSaturday: eatOnSaturday,
      eatOnSaturdayAndSunday: eatOnSaturdayAndSunday,
    };
}

export function getExtendedOptions(daysCount: number, mode?: ProgramModesEnum): ExtOptions {
  return {
    ...getEatOptions(daysCount),
    autoRenew: false,
    noBreakfastAndDinner: mode === ProgramModesEnum.NoBreakfast,
  } as ExtOptions;
}

